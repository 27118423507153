import { useRef, useState, useEffect } from 'react';
import '../styles/login.css';
import nuwAILogo from '../assets/NuwAI@2x.png';

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/msalConfig";

export default function LoginScreen() {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const timeout = async (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    const handleLogin = async (loginType) => {
        setAnchorEl(null);

        try {
            await instance.loginPopup({
                ...loginRequest,
                redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI,
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="main-ai-container login">
                <div className="ai-header login">
                    <img src={nuwAILogo} alt="NuwAI" className="ai-logo login" />
                </div>
                <div className="ai-header-divider login"></div>
                <div className="ai-center login">
                    <button onClick={() => handleLogin("popup")} type="submit" className="ai-login"><p alt="Enter" className="ai-login-enter">Login</p></button>
                </div>
            </div>
        </>
    );
}
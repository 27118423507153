import React, { useRef, useState, useEffect } from "react"

import Snackbar from '@mui/material/Snackbar';
import { ReactComponent as EnterInputIcon } from '../../assets/enter-input.svg'

export default function Input({ onSend }) {
    const [text, setText] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [inputWidth, setInputWidth] = useState("45%")
    const [inputHeight, setInputHeight] = useState("65px")
    const textAreaRef = useRef(null)

    const [snackText, setSnackText] = useState("")
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const handleClick = (text) => {
        setSnackText(text)
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpen(false);
        }
    };

    const handleSend = e => {
        e.preventDefault();
        if (inputValue == null || inputValue == "") {
            handleClick("Please provide a question.")
        } else {
            onSend(inputValue)
            setInputValue("")
            setInputWidth("45%")
        }

    }

    const onEnterPress = e => {
        if (e.code === "Enter" && e.shiftKey == false) {
            console.log("Enter key was pressed. Run your function.");
            e.preventDefault();
            handleSend(e);
        }
    }

    const handleChange = e => {
        const val = e.target?.value
        setInputValue(val)
    }

    const handleFocus = evt => {
        setInputWidth("100%")
    }

    const handleBlur = evt => {
        if (inputValue == null || inputValue == "") {
            setInputWidth("45%")
        }
    }

    return (
        <>
            <form onSubmit={handleSend} className="ai-input-box" style={{ width: inputWidth }}>
                <textarea
                    ref={textAreaRef}
                    rows={1}
                    value={inputValue}
                    type="text"
                    placeholder="Type here..."
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={onEnterPress}
                ></textarea>
                <button type="submit" class="ai-question-submit"><p alt="Enter" className="ai-enter viewing-pdf">Submit</p></button>
            </form>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                autoHideDuration={5000}
                key={vertical + horizontal}
                message={snackText}
            />
        </>
    )
}

import apiConfig from "../../config/api";

const API = {
  GetChatbotResponse: async (api, message, model, tone, references) => {
    const API_URL = `${apiConfig.ai[process.env.REACT_APP_API_ENV]}${
      process.env.REACT_APP_API_VER
    }`;

    if (api == "simplify") {
      console.log("API Message", message);
      console.log("API Model", model);
      console.log("API Tone", tone);
      console.log("API References", references);

      var bot_response = ""
      var fetchURL = `${API_URL}/simplify_question`

      var raw = JSON.stringify({
        "question": `${message}`,
        "model": `${model}`,
        "tone": `${tone}`,
        "references": references
      });

      await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        body: raw
      })
        .then(response => response.json())
        .then((result) => {
          bot_response = result
        })
        .catch((error) => {
          console.log("Error", error);
          bot_response = "There was an error"
        })

      return bot_response;
    } else if (api == "tax-advisor") {
      console.log("API Message", message);
      console.log("API Model", model);
      console.log("API Tone", tone);
      console.log("API References", references);

      var bot_response = ""
      var fetchURL = `${API_URL}/advisor_question`

      var raw = JSON.stringify({
        "question": `${message}`,
        "model": `${model}`,
        "tone": `${tone}`,
        "references": references
      });

      await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        body: raw
      })
        .then(response => response.json())
        .then((result) => {
          bot_response = result
        })
        .catch((error) => {
          console.log("Error", error);
          bot_response = "There was an error"
        })

      return bot_response;
    }
    else if (api == "normal") {
      console.log("API Message", message);
      console.log("API Model", model);
      console.log("API Tone", tone);
      console.log("API References", references);

      var bot_response = ""
      var fetchURL = `${API_URL}/ask_question`

      var raw = JSON.stringify({
        "question": `${message}`,
        "model": `${model}`,
        "tone": `${tone}`,
        "references": references
      });

      await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        body: raw
      })
        .then(response => response.json())
        .then((result) => {
          bot_response = result
        })
        .catch((error) => {
          console.log("Error", error);
          bot_response = "There was an error"
        })

      return bot_response;
    }
  },
  UploadFiles: async (formData) => {
    const API_URL = `${apiConfig.ai[process.env.REACT_APP_API_ENV]}${
      process.env.REACT_APP_API_VER
    }`;

    console.log("API FormData", formData);

    var bot_response = ""
    var fetchURL = `${API_URL}/upload_file`

    var raw = formData

    await fetch(fetchURL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      redirect: "follow",
      body: raw
    })
      .then(response => response.text())
      .then((result) => {
        bot_response = result
        console.log(bot_response);
      })
      .catch((error) => {
        console.log("Error", error);
        bot_response = "There was an error"
      })

    return bot_response;
  },
  SubmitFeedback: async (name, email, type, feedback, chatHistory) => {
    const API_URL = `${apiConfig.ai[process.env.REACT_APP_API_ENV]}${
      process.env.REACT_APP_API_VER
    }`;
    
    console.log("API Feedback Name", name);
    console.log("API Feedback Email", email);
    console.log("API Feedback Type", type);
    console.log("API Feedback Feedback", feedback);
    console.log("API Feedback History", chatHistory);

    var bot_response = ""
    var fetchURL = `${API_URL}/submit_feedback`

    var raw = JSON.stringify({
      "name": `${name}`,
      "email": `${email}`,
      "type": `${type}`,
      "feedback": `${feedback}`,
      "chatHistory": chatHistory
    });

    await fetch(fetchURL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      body: raw
    })
      .then(response => response.text())
      .then((result) => {
        bot_response = result
        console.log(bot_response);
      })
      .catch((error) => {
        console.log("Error", error);
        bot_response = "There was an error"
      })

    return bot_response;
  }
}

export default API

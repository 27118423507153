import React, { useState, useEffect, useRef } from "react"
import { ReactComponent as CopyIcon } from '../../assets/copy.svg'
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';

export default function UserMessage({ text }) {
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;

    const copyMessage = e => {
        e.preventDefault();
        console.log("Copy to clipboard")
        navigator.clipboard.writeText(text);
        handleClick();
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setOpen(false);
        }
    };

    return (
        <>
            <div className="user-message-container">
                <div className="user-message">{text}</div>
            </div>
            <div className="user-message-settings">
            <Tooltip title="Copy Question">
                <CopyIcon alt="Copy" className="ai-copy user" onClick={copyMessage} />
            </Tooltip>
            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                autoHideDuration={5000}
                key={vertical + horizontal}
                message="User Response copied to clipboard."
            />
        </>
    )
}
